import React from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useApp } from 'store/Application'
import SEO from 'components/Seo'
import { isBrowserIE } from 'utils/browser'
import 'assets/scss/pages/404.scss'

export default function Notice() {
  const { t } = useTranslation('error')
  const { noticeData } = useApp()
  const { title = '', message = '' } = noticeData || {}

  return (
    <div className={`PageNotFound-screen ${isBrowserIE() && 'ie'}`}>
      <div className="PageNotFound-container">
        <SEO title={t(title)} />
        <h4 className="PageNotFound-title">{t(message)}</h4>
        <p className="PageNotFound-desc">
          {t('lbl_click')}&nbsp;<span onClick={() => navigate('/')}>{t('lbl_here')}</span>&nbsp;
          {t('lbl_gohome')}
        </p>
      </div>
    </div>
  )
}
