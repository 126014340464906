/**
 * Detect user use IE browser
 */
export const isBrowserIE = () => {
  if (typeof window === 'undefined') return false

  return window.navigator.userAgent.match(/(MSIE|Trident)/)
}

/**
 * Detect user use old Edge browser
 */
export const isBrowserOldEdge = () => {
  if (typeof window === 'undefined') return false

  return window.navigator.userAgent.match(/Edge/)
}
